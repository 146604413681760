@import "./styles/reset.css";
@import "./styles/vars.css";

@font-face {
  font-family: "Polar-Book";
  src: url("/fonts/OTPolar-Book.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

:root {
  --global-columns: 4;
  --global-gutter: var(--dist-1-5);
  --global-gutters: calc((var(--global-columns) - 1) * var(--global-gutter));
  --global-column: calc(
    (100vw - 2 * var(--dist-1) - var(--global-gutters)) / var(--global-columns)
  );

  --bg-glass: hsla(209, 100%, 11%, 0.15);
  --bg-glass: hsla(209, 40%, 20%, 0.3);
}

@media (min-width: 1024px) {
  :root {
    --global-columns: 12;
  }
}

body {
  font-family: "Polar-Book";
  font-size: var(--fs-md);
  line-height: var(--lh-md);
  letter-spacing: 1%;
  color: var(--color-white);
  background: #a6b0b9;
}

html,
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behaviour: auto !important;
  background: #a6b0b9;
  transition: 5s background 0s;
  height: calc(-100 * var(--svh));
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

#__next {
}

body header + article {
  flex-grow: 1;
}
